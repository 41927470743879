// Close Modal Button
.close-modal {
    background-color: #5ab6f3;
    border-radius: 5px;
    color: white;
    width: 100%;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    border: none;
    &:active {
        transform: scale(0.95);
    }
}

.modal_body {
    text-align: center;
}

@media only screen and (max-height: 460px) {
    .close-modal {
        width: 75%;
    }
}
