.impirint {
    text-align: center;
    margin: 30px;
    display: inline;
}

.impirint_link {
    text-decoration: none;
    color: #66ccff;
    &:hover {
        color: #8fdaff;
    }
}

@media only screen and (max-width: 500px) {
    .impirint {
        margin: 25px;
    }
}
