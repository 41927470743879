.form-wrapper {
    flex: 1;
    margin: auto;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 20px;
}
.form-wrapper > * {
    margin: 15px;
}

.permission_view {
    margin: auto;
}

@media only screen and (max-width: 500px) {
    .form-wrapper > * {
        margin: 5px;
    }
    .form-wrapper {
        padding: 10px;
        max-width: 95%;
    }
}

@media only screen and (min-width: 500px) {
    .form-wrapper {
        max-width: 95%;
    }
}

@media only screen and (min-width: 768px) {
    .form-wrapper {
        max-width: 85%;
    }
}

@media only screen and (min-width: 1000px) {
    .form-wrapper {
        max-width: 65%;
    }
}
@media only screen and (min-width: 1200px) {
    .form-wrapper {
        max-width: 55%;
    }
}
