.session_modal_header {
    font-size: clamp(18px, 3vw, 28px) !important;
    font-weight: 300;
}

.session_modal_title {
    font-size: clamp(14px, 2vw, 18px) !important;
    font-weight: 300;
}

.session_modal_button {
    background-color: var(--inevvo-blue) !important;
    border: none !important;
}

.session_modal_button:focus {
    transform: scale(0.9) !important;
    box-shadow: none !important;
}

.session_input {
    border: none;
    border-bottom: 1px solid rgb(214, 214, 214);
    color: gray;
    padding: 10px 0;
    width: 50%;
    background-color: white !important;
    font-weight: 100;
}
.session_input:focus {
    outline: none;
}
