.response-wrapper {
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow);
}
.success_response {
    margin: auto 0;
}

.result_image {
    height: 50px;
    margin-right: 20px;
}
.fail_response {
    display: flex;
    align-items: center;
}
.result_text {
    margin: auto;
    font-weight: 500;
    text-align: center;
}

.error_title {
    text-align: center;
    color: red;
}

@media only screen and (max-width: 700px) {
    .response-wrapper {
        padding: 10px 20px;
    }

    .result_image {
        height: 30px;
    }
}
@media only screen and (max-width: 500px) {
    .response-wrapper {
        padding: 10px;
    }
}
