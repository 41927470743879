:root {
    --header-font-size: 23px;
    --inevvo-blue: #5ab6f3;
    --box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    --text-font-size: clamp(12px, 2.5vw, 18px);
}

body > * {
    font-size: var(--text-font-size) !important;
}
