.header_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 20px;
}

.header_logo {
    max-height: 55px;
}

.icon_text {
    padding: 0;
    margin: 0;
    margin-top: -12px;
    font-size: 23px !important;
}

.icon_border {
    border-bottom: 2.5px solid #afcb37;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .header_wrapper {
        width: 95%;
    }
    .header_logo {
        max-height: 40px;
    }

    .icon_text {
        margin-top: -6px;
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 500px) {
    .header_wrapper {
        width: 95%;
    }
}

@media only screen and (min-width: 768px) {
    .header_wrapper {
        width: 85%;
    }
}

@media only screen and (min-width: 1000px) {
    .header_wrapper {
        max-width: 65%;
    }
}
@media only screen and (min-width: 1200px) {
    .header_wrapper {
        max-width: 55%;
    }
}
