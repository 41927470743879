#video {
    width: 100%;
    height: auto;
}

@media only screen and (max-height: 460px) {
    #video {
        width: 100%;
        max-height: 70vh;
    }
}
