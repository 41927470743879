.language_select {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 1em;
    .select-search {
        float: right;
        width: 4.5em;
        font-family: "Nunito Sans", sans-serif;
    }
    .select-search__input {
        padding: 0 0 0 5px;
        background: rgb(249, 249, 249);
        box-shadow: var(--box-shadow);
        font-size: 14px;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        margin-top: 3px;
    }

    .select-search__option {
        font-size: 14px;
        padding: 0px 10px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 768px) {
    .language_select {
        .select-search {
            width: 3.5em;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .language_select {
        .select-search {
            width: 3em;
        }
    }
}
