.box_component_wrapper {
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 10px 20px;
}

.input_wrapper {
    display: flex;
    margin: 0;
    padding: 0;
}

.box_image {
    display: block;
    width: 100px;
    margin-right: 10px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    &:hover {
        cursor: pointer;
    }
    &:active {
        transform: scale(0.95);
    }
}

.box_input_group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.box_button_group {
    border: none;
    border-radius: 5px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    &:active {
        transform: scale(0.97);
    }
    &:hover {
        border: none;
    }
}

.scan_button {
    background-color: rgb(238, 238, 238);
    &:hover {
        background-color: black;
        color: white;
    }
}

.box_input {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    padding-left: 5px;
    width: 100% !important;
    text-overflow: ellipsis;
}

.submit_button {
    background-color: #5ab6f3;
    color: white;
    width: 100%;
    margin-top: 5px;
    border: 2px solid #5ab6f3;
    &:hover {
        border: 2px solid #5ab6f3;
        background-color: white;
        color: #5ab6f3;
    }
}

@media only screen and (max-width: 700px) {
    .box_image {
        width: 70px;
    }
}

@media only screen and (max-width: 500px) {
    .box_component_wrapper {
        padding: 10px;
    }
    .box_image {
        width: 70px;
    }
    .scan_button {
        margin-bottom: 2px;
        height: 25px;
    }
}
